import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Alert,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { withRouter } from 'react-router-dom';
import { FormText } from '../../Objects/Form';
import { ModalAlert } from '../../Objects/Modal';
import { Decimal } from 'decimal.js';

const Default = () => {

	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [item, setItem] = useState({});
	const [shipping, setShipping] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);

	useEffect(() => {
	
		const getData = async () => {
			
			const getCart = async () => {
			
				//カート情報の取得
				const ref = await API.Cart.Query();
				
				//データセット
				setData(ref.items);		

				let total_price = new Decimal(0);
				let total_unit_price = new Decimal(0);
				
				for (const item of ref.items) {
					const sub_price = new Decimal(item.price).times(item.piece);
					const sub_unit_price = new Decimal(item.unit_price).times(item.piece);
				
					total_price = total_price.plus(sub_price);
					total_unit_price = total_unit_price.plus(sub_unit_price);
				}

				const ref2 = await API.Param.Scan();
				//console.log(ref2.items);
	
				const shipping = new Decimal(ref2?.items?.find((v) => v.name === 'shipping')?.value ?? 0);
				const free_shipping_price = new Decimal(ref2?.items?.find((v) => v.name === 'free_shipping_price')?.value ?? 0);
				
				let new_shipping = new Decimal(0);
				
				if (total_unit_price.lessThan(free_shipping_price)) {
					setShipping(shipping.toNumber());
					new_shipping = shipping;
				}
				else{
					setShipping(0);
				}

				total_price = total_price.plus(new_shipping);

				//保存
				setTotalPrice(total_price.toNumber());
			};
			
			const getUser = async () => {
				
				//ユーザ情報の取得
				const ref = await API.User.Current();
				
				//データセット
				setItem(ref.item);
			};
			
			try{
				//カート情報の取得
				await getCart();
				
				//ユーザ情報の取得
				await getUser();
				
			
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSubmit = async (event) => {
	
		const createNewData = async (data) => {

			const newData = [];
			
			for(const item of data){
				
				const price = Number(item.price ?? 0);
				const piece = Number(item.piece ?? 0);
				const sub_price = new Decimal(price).times(piece).toNumber();
				const unit_price = Number(item.price ?? 0);

				const params ={
					item_id : item.item_id,
					product_no : item.product_no,
					product_name : item.product_name,
					gousu : item.gousu,
					minimum_unit : item.minimum_unit,
					category1_name : item.category1_name,
					category1_code : item.category1_code,
					category2_name : item.category2_name,
					category2_code : item.category2_code,
					genre_name : item.genre_name,
					genre_code : item.genre_code,
					catalog_no : item.catalog_no,
					keyword : item.keyword,
					sort : item.sort,
					boxed_num : item.boxed_num,
					url : item.url,
					pre_order_num : item.pre_order_num,
					stock_flag : item.stock_flag,
					message : '',
					price : price,
					piece: piece,
					sub_price: sub_price,
					unit_price : unit_price,
				};
				newData.push(params);
			}
			return(newData);
		};
		
		const getTotalPrice = async (data) => {
			
			let total_price = new Decimal(0);
			let total_unit_price = new Decimal(0);
			
			for (const item of data) {
				const sub_price = new Decimal(item.price).times(item.piece);
				const sub_unit_price = new Decimal(item.unit_price).times(item.piece);
				total_price = total_price.plus(sub_price);
				total_unit_price = total_unit_price.plus(sub_unit_price);
			}

			const ref = await API.Param.Scan();

			const shipping = new Decimal(ref?.items?.find((v) => v.name === 'shipping')?.value ?? 0);
			const free_shipping_price = new Decimal(ref?.items?.find((v) => v.name === 'free_shipping_price')?.value ?? 0);

			let new_shipping = new Decimal(0);
			if (total_unit_price.lessThan(free_shipping_price)) {
				new_shipping = shipping;
			}

			total_price = total_price.plus(new_shipping);

			return {
				total_price: total_price.toNumber(),
				new_shipping: new_shipping.toNumber()
			};
		};
		
		const clearCart = async (data) => {
		
			for(const item of data){
				const ref = await API.Cart.Delete(item.item_id);
				console.log(ref);
			}
		};
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//カートの中身チェック
			if(data.length === 0){
				throw new Error('カートの中身がありません。');
			}
			
			//受注商品データの確認と取得
			const newData = await createNewData(data);
			
			//合計金額の取得
			const {total_price, new_shipping} = await getTotalPrice(newData);

			//console.log(total_price, new_shipping);
			
			//更新処理
			const params ={
				email : item.email,
				representative_email : item.representative_email,
				user_no : formObj.user_no.value,
				user_name : formObj.user_name.value,
				zip : formObj.zip.value,
				addr : formObj.addr.value,
				company : formObj.company.value,
				person : formObj.person.value,
				tel : formObj.tel.value,
				mobile : formObj.mobile.value,
				fax : formObj.fax.value,
				items : newData,
				total_price: total_price,
				note : formObj.note.value,
				shipping : new_shipping,
				status: '注文の登録完了',
			};
			//console.log(params);
			
			//更新
			const ref = await API.Order.Put(params);
			console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + JSON.stringify(ref.error) + ')');
			}
			
			//カートの中身削除
			await clearCart(newData);

			throw new Error('発注しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (item_id) => {

		//Formをロック
		setLock(true);
		
		try {
			//削除
			const ref = await API.Cart.Delete(item_id);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			throw new Error('更新しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] === '発注しました。'){
			window.location.href = '/order-history/';
		}
		else{
			setReload(reload+1);
		}
	};
	
	const handlePieceChange = async (item_id, piece, minimum_unit) => {

		//Formをロック
		setLock(true);
		
		try {

			if(Number(piece) < Number(minimum_unit)){
				piece = minimum_unit;
			}

			//更新
			const params ={
				item_id : item_id,
				piece : Number(piece),
			};

			//更新
			const ref = await API.Cart.Update(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			//throw new Error('更新しました。');
			setReload(reload+1);
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const formatItemLink = (cell, row) => {
		return (
			<div>
				<a href={'/item/' + row.item_id}>{cell}</a>
			</div>
		);
	};
	
	const formatPrice = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatPiece = (cell, row) => {
		return (
			<div className="text-right">
				<a name={'#' + row.item_id} href={'#' + row.item_id}>
					{Number(cell ?? 0).toLocaleString()} 個
				</a>
				　[ <a href={'#' + row.item_id} onClick={() => handlePieceChange(row.item_id, Number(cell + row.minimum_unit), row.minimum_unit)} disabled={lock}>＋</a> | <a href={'#' + row.item_id} onClick={() => handlePieceChange(row.item_id, Number(cell - row.minimum_unit), row.minimum_unit)} disabled={lock}>－</a> ]
			</div>
		);
	};
	
	const formatSubPrice = (cell, row) => {
		const sub_price = Number(row.price) * Number(row.piece);
		return (
			<div className="text-right">
				{Number(sub_price ?? 0 ).toLocaleString()} 円
			</div>
		);
	};
	
	const formatDelete = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => handleDelete(row.item_id)} disabled={lock} >
					削除
				</Button>
			</div>
		);
	};
	
	const columns = [
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			formatter: formatItemLink,
		},
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			formatter: formatItemLink,
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			formatter: formatItemLink,
		},
		{
			dataField: 'gousu',
			text: '号数全角',
			sort: true,
		},
		{
			dataField: 'price',
			text: 'ﾒｰｶｰ希望本体価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
		},
		{
			dataField: 'piece',
			text: '個数',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPiece,
		},
		{
			dataField: 'dummy1',
			text: '合計金額',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatSubPrice,
		},
		{
			dataField: 'dummy2',
			text: '削除',
			sort: false,
			headerClasses: 'text-center',
			formatter: formatDelete,
		},
	];

	
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<Row>
					<Col sm={12}>
						<h4>発注画面</h4>
					</Col>
				</Row>

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>お客様の情報</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'Eメール'} name={'email'} type={'email'} value={item.email} readOnly={true} />
					<FormText label={'代表者Eメール'} name={'representative_email'} type={'email'} value={item.representative_email} readOnly={true} />
					<FormText label={'得意先コード'} name={'user_no'} value={item.user_no} required={true} />
					<FormText label={'お名前'} name={'user_name'} value={item.user_name} required={true} />
					<FormText label={'郵便番号'} name={'zip'} value={item.zip} />
					<FormText label={'住所'} name={'addr'} value={item.addr} />
					<FormText label={'会社名'} name={'company'} value={item.company} />
					<FormText label={'担当者名'} name={'person'} value={item.person} />
					<FormText label={'電話番号'} name={'tel'} type={'tel'} value={item.tel} />
					<FormText label={'携帯電話'} name={'mobile'} type={'tel'} value={item.mobile} />
					<FormText label={'FAX番号'} name={'fax'} type={'tel'} value={item.fax} />
					<hr/>
					<Row>
						<Col sm={6}>
							<p><big>商品の情報</big></p>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<Table keyField={'item_id'} data={data} columns={columns} />
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col sm={6}>
							<p><big>料金の情報</big></p>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<table className="table  table-bordered">
								<tbody>
								<tr>
									<td>合計点数</td>
									<td className="text-right">{ Number(data?.length ?? 0).toLocaleString() } 点</td>
								</tr>
								<tr>
									<td>送料</td>
									<td className="text-right">{ Number(shipping ?? 0).toLocaleString() } 円</td>
								</tr>
								<tr>
									<td>合計金額</td>
									<td className="text-right">{ Number(totalPrice ?? 0).toLocaleString() } 円</td>
								</tr>
								</tbody>
							</table>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col sm={6}>
							<p><big>連絡欄</big></p>
						</Col>
					</Row>
					<textarea name="note" className="form-control">
					</textarea>
					<hr/>
					<Alert variant="danger">
						<p>注意 ＜送料に関して＞</p>
						<p>
						送料基準に満たない場合、送料が別途発生いたします。
						他のご注文とまとめることができません。
						あらためて送料負担の有無をご確認のうえ発注くださいますようお願いいたします。</p>
					</Alert>
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={12}>
						        <Button variant="dark" type="submit" block="true" disabled={lock} >発注</Button>
								　
						        <Button variant="secondary" type="button" block="true" onClick={()=>{ window.location.href='/cart/'; }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
